#navbar {
	opacity: 1;
	z-index: 20;
	box-shadow: none;
	background-color: rgba(0, 0, 0, 0.85);
	box-shadow: none;
	height: 0vh;
	transform: scaleY(0);
	transform-origin: top center;
	transition: transform 0.7s cubic-bezier(.19,1,.22,1);
	position: sticky;
}

.logo-image-navbar {
	height: 30%;
	display: inline-block;
	opacity: 0;
	transition: opacity 0.7s cubic-bezier(.19,1,.22,1);
}

div.logo-image-navbar {
	display: inline-block;
	margin-left: 5%;
	font-size: 15pt;
	vertical-align: top;
	height: 1.2em;
	margin-top: 0px;
}

.links {
	text-align: right;
	position: relative;
	display: block;
	float: right;
	left: -4vw;
}

button:focus {
	outline: 0;
}

.btn {
	border: none;
	background-color: rgba(0, 0, 0, 0);
	font-style: none;
	margin-left: 2.5em;
	font-weight: lighter;
	font-family: 'Montserrat', Georgia, 'Times New Roman', Times, serif;
}

.btn-main {
	border: 0.1rem solid #aa0c31;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 8px;
	padding: 5px 20px;
	transition: background-color 0.7s cubic-bezier(.19,1,.22,1);
	color: lightgrey;
	font-size: 20px;
	text-decoration: none;
	font-weight: lighter;
}

.btn-main:hover {
	background-color: #aa0c31;
	cursor: pointer;
	color: white;
}

.navbar-content {
	vertical-align: baseline;
	font-family: 'Montserrat', Georgia, 'Times New Roman', Times, serif;
	opacity: 1;
	z-index: 21;
	position: sticky;
	top: 3.5vh;
	width: 100vw;
	height: 100px;
	padding: 0 2vw;
	transition: top 0.7s cubic-bezier(.19,1,.22,1);
	margin-top: -3.5vh;
	overflow: visible;
}

#app-drawer {
	background-color: rgba(0, 0, 0, 0.85);
	padding: 20px;
}

#app-drawer a {
	padding: 0px;
}

#menu-icon {
	float: right;
	margin-right: 10%;
	margin-top: 1px;
}