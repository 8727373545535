.overlay {
	width: 100vw;
	height: 100%;
	position: absolute;
	background-color: #000;
	opacity: 0;
	z-index: 4;
	top: 0;
	transform: translateZ(-1px) translateY(0) scale(2);
}

.image-mask {
	width: 100vw;
	height: 100%;
	position: absolute;
	background-color: rgba(26, 26, 26, 0.65); /*rgb(71, 6, 21, 0.5);*/
	z-index: -1;
}

.parallax-wrapper {
	width: 100vw;
	height: 100vh;
	box-sizing: border-box;
	transform-style: preserve-3d;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: -1;
}

.before-pw {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-image: url("../images/harbour.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: fixed;
	z-index: -1;
	transform: translateZ(-1px) translateY(0) scale(2);
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.content-image {
	margin: 0 0;
	width: 100%;
	text-align: center;
	/*text-shadow: 2px 2px 2px grey;*/
	font-family: "Montserrat", Georgia, "Times New Roman", Times, serif;
	transform: translateZ(-1px) scale(2);
	font-weight: 100;
	position: relative;
	opacity: 1;
	z-index: 3;
}

#main-logo {
	margin-bottom: 0;
}

.motto {
	color: white;
	font-weight: lighter;
	font-style: italic;
	box-sizing: border-box;
	position: absolute;
	bottom: -20%;
	transform: translateZ(-1px) scale(2);
}

.principal {
	margin-top: 0;
	color: white;
	font-weight: lighter;
}


@supports (-webkit-touch-callout: none) {
	#navbar {
		position: fixed;
		top: 0;
		left: 0;
	}

	/* #first-section-wrapper {
		transform: translateY(-10px);
	} */

	.before-pw, .overlay, .content-image, .motto {
		transform: none;
	}

	.regular-wrapper {
		margin-top: 95vh;
		transform-style: preserve-3d;
		position: initial;
	}
}