.closing-icon {
	position: absolute;
	opacity: 0;
	visibility: hidden;
	top: 20px;
	cursor: pointer;
	z-index: 10;
	font-size: 15pt !important;
	transition: opacity 1s ease, visibility 1s ease !important;
}

.box-content {
	opacity: 0;
	position: absolute;
	visibility: hidden;
	font-size: 15pt;
	transition: opacity 1s ease, visibility 1s ease !important;
}

.box-content-title {
	font-size: 50pt;
	text-shadow: 2px 2px 2px black;
	position: absolute;
	z-index: 1;
	margin: 0;
	opacity: 0;
	transition: opacity 1s ease, visibility 1s ease !important;
	padding: 20px;
	box-sizing: border-box;
}

@media screen and (max-width:374px) and (min-width:0px) {
	.box-content-title {
		font-size: 60%;
	}

	.box-content {
		padding-top: 40px;
		font-size:6pt;
	}

	.closing-icon {
		top: 10px;
		padding-top: 0;
		font-size: 17pt !important;
	}
}

@media screen and (max-width:599px) and (min-width:375px) {
	.box-content-title {
		font-size: 70%;
	}

	.box-content {
		padding-top: 40px;
		font-size:7pt;
	}

	.closing-icon {
		top: 20px;
		padding-top: 0;
		font-size: 15pt !important;
	}
}

@media screen and (max-width:959px) and (min-width:600px) {
	.box-content-title {
		font-size: 130%;
	}

	.box-content {
		padding-top: 100px;
		font-size: 12pt;
	}

	.closing-icon {
		font-size: 20pt !important;
	}
}

@media screen and (max-width:1279px) and (min-width:960px) {
	.box-content-title {
		font-size: 35pt;
	}

	.box-content {
		font-size: 9pt;
	}

	.closing-icon {
		font-size: 20pt !important;
	}
}

@media screen and (max-width:1919px) and (min-width:1280px) {
	.box-content-title {
		font-size: 40pt;
	}

	.box-content {
		font-size: 12pt;
	}

	.closing-icon {
		font-size: 20pt !important;
	}
}