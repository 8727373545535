.box-link {
	cursor: pointer;
	opacity: 0;
	visibility: hidden;
	transition: visibility 1s ease 1s;
	font-size: 20pt !important;
	line-height: 2em;
}

.links-container a {
	margin: 0;
}

.links-container .no-click:hover {
	color: lightgrey;
	cursor: default;
}


@media screen and (max-width:374px) and (min-width:0px) {
	.box-link {
		font-size: 13pt !important;
	}
}

@media screen and (max-width:599px) and (min-width:375px) {
	.box-link {
		font-size: 14pt !important;
	}
}

@media screen and (max-width:959px) and (min-width:600px) {
	.box-link {
		font-size: 22pt !important;
	}
}

@media screen and (max-width:1279px) and (min-width:960px) {
	.box-link {
		font-size: 17pt !important;
	}
}