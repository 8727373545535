.segment {
	width: 100%;
	height: auto;
	margin: 0;
	padding: 0;
	border: none;
	text-align: center;
}

.segment#form {
	width: auto;
	text-align: center;
}

.segment-children#form {
	padding-right: 0;
	padding-top: 0;
}

@media screen and (max-width:959px) and (min-width:0px){
	
}

.segment-children#about {
	padding: 5% 15%; 
}

.segment-children#interactive {
	padding: 8% 4%;
	position: relative;
}

.segment-title {
	color: #aa0c31;
	margin: 0;
	margin-right: 0;
	vertical-align: middle;	
	flex-grow: 1;
	text-align: right;
	padding-right: 0;
}

.even {
	background-color: rgba(238, 238, 238, 0.5);
	color: white;
}

.odd {
	background-color: white;
	color:grey
}

.image-title-container {
	display: flex;
	text-align: left;
	align-items: center;
}

.logo-image-segment {
	max-height: 60px;
	height: auto;
	margin: 10px;
	margin-top: 20px;
	margin-left: 0px;
	vertical-align: middle;
	padding-left: 40px;
}