input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.form-message {
	font-size:17px !important;
	position:absolute;
	opacity: 0;
	visibility: hidden;
	transition: opacity 1s ease;
}

@media screen and (min-width:959px) and (max-width: 1279px) {
	form {
		padding-top: 10%;
	}
}

@media screen and (max-width:1919px) {
	form {
		padding-bottom: 10%;
	}
}

/* Overrides the default color when you hover over a textfield*/
.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: 2px solid lightgray !important;
}

.MuiInput-underline:before {
	border-bottom: 1px solid lightgray !important;
}

.MuiInputBase-input {
	color: white !important;
}

.MuiFormLabel-root {
	color: white !important;
}

.MuiButton-containedPrimary {
	border: 1px solid white !important;
	background-color: white !important;
	color: #aa0c31 !important;
}

.MuiButton-containedPrimary:hover {
	background-color: #aa0c31 !important;
	color: white !important;
}

.Mui-focused {
	color: rgba(211, 211, 211, 0.8) !important;
}


#contact-cell {
	background-color: #aa0c31;
	color: lightgray;
}


/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	background-color: rgba(0, 0, 0, 0);
	color: white;
}


/* SPINNER CSS */

#spinner {
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.7s cubic-bezier(.19,1,.22,1);
}

.lds-ellipsis {
    display: inline-block;
    position: absolute;
    width: 80px;
	height: 80px;
	left:calc(50% - 40px);
	top: 0;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: white;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  