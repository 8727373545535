.portrait-grid-container {
	visibility: visible;
	opacity: 1;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0;
	box-sizing: border-box;
}

.portrait-top-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;

	height: 50%;
	width: 100%;
	top:0;
}

.portrait-bottom-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	position: relative;

	height: 50%;
	width: 100%;
	bottom: 0;
}

.portrait-top-cell {
	height: 100%;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	/* transition: height 1s ease, width 1s ease, padding 1s ease, opacity 1s ease,
		visibility 1s ease; */
	transition-property: height, width, opacity, padding, visibility, top;
	transition-duration: 1s;
	transition-timing-function: ease;
	position: absolute;
	top: 10%;
}

.portrait-left-cell {
	height: 100%;
	width: 50%;
	padding: 10px;
	box-sizing: border-box;
	/* transition: height 1s ease, width 1s ease, padding 1s ease, opacity 1s ease,
		visibility 1s ease; */
	transition-property: height, width, opacity, padding, visibility, bottom;
	transition-duration: 1s;
	transition-timing-function: ease;
	position: absolute;
	left: 0;
	bottom: 0;
}

.portrait-right-cell {
	height: 100%;
	width: 50%;
	padding: 10px;
	box-sizing: border-box;
	/* transition: height 1s ease, width 1s ease, padding 1s ease, opacity 1s ease,
		visibility 1s ease; */
	transition-property: height, width, opacity, padding, visibility;
	transition-duration: 1s;
	transition-timing-function: ease;
	position: absolute;
	right: 0;
	bottom: 0;
}

.portrait {
	z-index: 0;
	border-radius: 200px;
	height: 100%;
}

.portrait-container {
	box-sizing: border-box;
	display: inline-block;
	height: calc(100% - 20px); /* 10px padding top and bottom */
	position: relative;
	border-radius: 100%;
	overflow: hidden;
	/* transition: opacity 1s ease, visibility 1s ease, height 1s ease; */
	transition-property: height, opacity, visibility, bottom;
	transition-duration: 1s;
	transition-timing-function: ease;
}

.portrait-overlay {
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.6);
	transition: background-color 1s;
	z-index: 2;
	position: absolute;
	border-radius: 300px;
}

.portrait-overlay:hover {
	background-color: rgba(0, 0, 0, 0);
	cursor: pointer;
}

.portrait-name {
	position: absolute;
	bottom: 0;
	height: 20%;
	font-size: 2.7vw;
	opacity: 0;
	visibility: hidden;
	/* transition: opacity 1s ease, visibility 1s ease; */
	transition-property: opacity, visibility;
	transition-duration: 1s;
	transition-timing-function: ease;
}

.portrait-summary {
	position: absolute;
	font-family: "Montserrat", Georgia, "Times New Roman", Times, serif;
	left: 5%;
	width: 35%;
	visibility: hidden;
	opacity: 0;
	font-size: 1.1vw;
	/* transition: opacity 1s ease, visibility 1s ease; */
	transition-property: opacity, visibility;
	transition-duration: 1s;
	transition-timing-function: ease;
}

/*	These apply to all screen sizes that are "large" 
	ie. all sizes where the boxes expand left or right
*/
.portrait-expanded {
	width: 100%;
	height: 200%;
	padding-top: 10%;
	padding-bottom: 15%;
	bottom: 0;
}

.portrait-expanded.portrait-top-cell {
	width: 100%;
	height: 200%;
	padding-top: 10%;
	padding-bottom: 15%;
	top: 0;
}

.row-expanded {
	height: 100%;
	top: 0;
}

.portrait-grid-container-expanded {
	width: 48%;
}

/*	these apply to all screen size that are "small" 
	ie. all screen sizes where the boxes stack.
*/
@media screen and (min-width: 0px) and (max-width: 959px) {
	.portrait-name {
		top: 60%;
	}

	.portrait-summary {
		padding-top: 40px;
		width: 90%;
	}

	.portrait-expanded {
		width: 100% !important;
		height: 200%;
		padding-bottom: 30%;
		bottom: 0;
	}

	.portrait-expanded.portrait-top-cell {
		width: 100% !important;
		height: 200%;
		padding-bottom: 30%;
		top: 0;
	}

	.portrait-grid-container-expanded {
		width: 100%;
	}
}

/* xs */
@media screen and (max-width: 374px) and (min-width: 0px) {
	.portrait-summary {
		top: 50%;
		font-size: 2.4vw;
	}
	.portrait-name {
		font-size: 4vw;
	}
}

/* xs */
@media screen and (max-width: 599px) and (min-width: 375px) {
	.portrait-summary {
		top: 55%;
		font-size: 2.1vw;
	}

	.portrait-name {
		font-size: 4.5vw;
	}
}

/* sm */
@media screen and (max-width: 959px) and (min-width: 600px) {
	.portrait-summary {
		top: 65%;
		font-size: 1.8vw;
	}

	.portrait-name {
		font-size: 4.5vw;
	}
}

/* md */
@media screen and (max-width: 1279px) and (min-width: 960px) {
	.portrait-summary {
		font-size: 1.2vw;
	}

	.portrait-name {
		font-size: 3vw;
	}
}

/* lg */
@media screen and (max-width: 1919px) and (min-width: 1280px) {
	.portrait-summary {
		font-size: 1.1vw;
	}

	.portrait-name {
		font-size: 2.7vw;
	}
}
