#root {
	height: 100%;
	perspective: 1px;
	overflow-x: hidden !important;
	overflow-y: scroll;
	padding: 0;
	display: block;
	z-index: -10;
}

#root::-webkit-scrollbar {
	display: none;
}

html {
	scroll-behavior: smooth;
}

body {
	height: 100vh;
	margin: 0;
	font-size: 26px;
	font-family: "Montserrat", Georgia, "Times New Roman", Times, serif;
	color: #ffffff;
	perspective: 1px;
	transform-style: preserve-3d;
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.regular-wrapper {
	margin-top: 0;
	width: 100vw;
	height: fit-content;
	z-index: 10;
	position: fixed;
	background-color: white;
	text-align: center;
}

.content {
	margin: 0 0;
	width: 100vw;
	height: fit-content;
	background: #fff;
	position: relative;
	display: inline-block;
	z-index: 10;
}

a {
	color: lightgrey;
	/*margin-left: 2.5em;*/
	text-decoration: none;
	font-size: 20px;
	font-weight: lighter;
	transition: color 0.7s cubic-bezier(0.19, 1, 0.22, 1);
}

a:hover {
	color: white;
}

.hidden {
	visibility: hidden;
	width: 0;
	height: 0;
}

b {
	font-size: 1.5em;
}
