.contact-details-container {
	text-align:left;
}

#contact-details-title {
	margin-bottom: 20px;
	font-weight: lighter;
	color: white;
}

.contact-details-text {
	font-size: 20px;
	margin-bottom: 10px;
	display: flex;
}

.contact-details-social-media {
	display: flex;
}

.contact-details-social-media-link {
	font-size: 20px;
	margin-bottom: 10px;
	margin-right: 10px;
}

.contact-details-social-media-link a {
	cursor: pointer;
}

.social-icon:hover {
	fill: lightgray !important;
}

.contact-details-container a {
	color: lightgray;
	text-decoration: none;
	font-size: 20px;
	font-weight: lighter;
	transition: all 0.5 ease-in-out;
}

.contact-details-container a:hover {
	color: white;
}

#contact-details-cell {
	display:flex;
	align-items:center;
	justify-content:center;
	padding-top: 5%;
	padding-bottom: 10%;
	flex-direction: column;
}

.contact-us-title {
	display:flex;
	align-items:center;
	justify-content:center;
}

.contact-us-title-container {
	text-align:left;
	font-size: 40pt;
	font-weight: lighter;
	padding-bottom: 10%;
	color: white;
	padding-top: 10%;
	display: inline-block;
}

#contact-cell {
	position: relative;
}

#contact-us-logo {
	max-width: 63%;
	padding-bottom: 2%;
	padding-left: 0;
	align-self: flex-start;
}

/* xs */
@media screen and (max-width:599px) and (min-width:0px) {

	.contact-us-title-container {
		font-size: 10vw;
		text-align: left !important;
	}

	#contact-details-title {
		font-size: 7vw;
	}

	.contact-details-text {
		font-size: 4.5vw;
	}

	.contact-details-text a {
		font-size: 4.5vw;
	}

	.icon {
		font-size: 6vw !important;
	}

	#contact-us-logo {
		max-width: 75%;
	}
}