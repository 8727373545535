.interactive-box {
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-color: black;
	width:100%;
	visibility: visible;
	transition: width 1s ease, opacity 1s ease, visibility 1s ease, background-size 1s ease, background-image 0.5s ease;
	position: relative;
}

#our-people.interactive-box {
	background-image: url("../images/library.png");
	background-position: 100% 0%;
}

#areas-of-law.interactive-box {
	background-image: url("../images/pantheon.png");
	background-position: 0% 0%;
}

.box-overlay {
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	transition: background-color 0.5s;
}

.interactive-box-title {
	font-size: 50pt;
	font-weight: lighter;
	text-shadow: 2px 2px 2px black;
	z-index: 1;
	position: absolute;
	align-self: center;
	margin: 0;
	visibility: visible;
	opacity: 1;
	transition: opacity 0.2s linear, visibility 0.2s linear;
}
/*Change the font size of the title based on the width of the screen*/
@media screen and (max-width:599px) and (min-width:0px) {
	.interactive-box-title {
		font-size: 25pt;
	}
}

@media screen and (max-width:959px) and (min-width:600px) {
	.interactive-box-title {
		font-size: 35pt;
	}
}

@media screen and (max-width:1279px) and (min-width:960px) {
	.interactive-box-title {
		font-size: 35pt;
	}
}

@media screen and (max-width:1919px) and (min-width:1280px) {
	.interactive-box-title {
		font-size: 40pt;
	}
}